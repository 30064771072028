import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div>
      <header className="App-header">
       SECRET-HITLER
       {/* Execute Felix🔫 */}
     </header>
     <br></br>
     <div style={{backgroundColor: "#222222", width: "50vmin", margin: "0 auto", padding: "20px"}}>
                        <p>
                            Hello! Welcome to SECRET-HITLER! Please login with your TAMU NETID to continue.
                        </p>
                    </div>
      <button onClick={() => loginWithRedirect()}>Log In</button>
    </div>
  );
};

export default LoginButton;